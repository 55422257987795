export default {
    id: null,
    name: null,
    description: null,
    config: {
        conditions: null
    },
    order: 100,
    active: true,
    form: null
}
